import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { SearchIcon } from '../../utils/functions/Icons';
import ConfirmModal from '../common/ConfirmModal';
import { useEffect, useState } from 'react';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import {  getLabtestApi, updateLabOrderApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
export default function Scheduled() {
    const [searchValue, setSearchValue] = useState('')
    const [year, setYear] = useState<string | number>("0");
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [labTestData, setLabTestData] = useState<LabTest[] | []>([])
    const [particularData, setParticularData] = useState<LabTest | null>(null)
    const navigate = useNavigate();

    const handleCancelAppointment = (event: React.MouseEvent<HTMLButtonElement>, item: LabTest) => {
        event.stopPropagation();
        setParticularData(item);
        setOpenConfirmModal(true)
    }
    const CloseConfirmModal = () => {
        setOpenConfirmModal(false)
    }

    const fetchLabtestApi = async (data: { search: string, status: string, filter: string | number }) => {
        try {
            setLoading(true)
            const result = await getLabtestApi(data);
            if (result?.success) {
                setLoading(false)
                setLabTestData(result?.labTests);
            }
            else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                fetchLabtestApi({ search: searchValue || '', status: 'confirmed', filter: year });
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            fetchLabtestApi({ search: searchValue || '', status: 'confirmed', filter: year });
        }
    }, [searchValue, year]);


    const confirmSubmit = async () => {
        try {
            setBtnLoading(true)
            const result = await updateLabOrderApi({ orderId: particularData?.labOrderId, status: 'Completed' });
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                fetchLabtestApi({ search: searchValue, status: 'confirmed', filter: year });
                CloseConfirmModal();
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

  
    const handleViewDetails = (item: LabTest) => {
        navigate('/LabTests/details', {
            state: {
                id: item?.labOrderId,
                labName: item?.labName
            }
        });
    };


    return (
        <div>
            <div className={'mb-6 flex justify-between items-center w-full max-w-full'}>
                <FormGroup className="customFormGroup w-1/2">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='search'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
                <FormGroup className="customFormGroup w-36">
                    <Select
                        className={`custumTextField profileInput`}
                        placeholder="Date"
                        defaultValue={"0"}
                        value={year}
                        onChange={(e) => setYear(e.target?.value)}
                    >
                        <MenuItem selected value="0">All</MenuItem>
                        <MenuItem value="1">Last Month</MenuItem>
                        <MenuItem value="2">6 Months</MenuItem>
                        <MenuItem value="3">1 Year</MenuItem>
                    </Select>
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow className={styles.completeTableRow}>
                                    <TableCell>Patient Name</TableCell>
                                    {/* <TableCell align="right">Test Name</TableCell> */}
                                    <TableCell align="right">City</TableCell>
                                    <TableCell align="right">Lab Name</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                    <TableCell align="right">Time</TableCell>
                                    {/* <TableCell align="right">Type of test</TableCell> */}
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                labTestData?.length > 0 ?
                                    <TableBody>
                                        {
                                            labTestData?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        onClick={() => handleViewDetails(item)}
                                                        className='custumTableRow cursor-pointer'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {item?.patientName}
                                                        </TableCell>
                                                        {/* <TableCell component="th" scope="row">
                                                            {item?.type}
                                                        </TableCell> */}
                                                        <TableCell component="th" scope="row">
                                                            {item?.labCityName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.labName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.bookingDate}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.bookingTime}
                                                        </TableCell>
                                                        {/* <TableCell component="th" scope="row">
                                                            <button className={`outLineBtn w-full ${styles.downloadPresBtn}`}>{item?.types}</button>
                                                        </TableCell> */}
                                                        <TableCell component="th" scope="row">
                                                            <button className={styles.cancelAppointment} onClick={(event) => handleCancelAppointment(event, item)}>Completed</button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={5}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }

            <ConfirmModal
                openConfirmModal={openConfirmModal}
                CloseConfirmModal={CloseConfirmModal}
                confirmSubmit={confirmSubmit}
                btnLoading={btnLoading}
                title='Are you sure, you want to completed this appointment'
            />
        </div>
    );
}